export interface Mpp {
    honorific: string;
    first_name: string;
    last_name: string;
    gender: string;
    riding_name: string;
    party: string;
    role: string;
    image: string;
}

export const MPPS: Mpp[] = [
    {
        "honorific": "",
        "first_name": "Deepak",
        "last_name": "Anand",
        "gender": "Male",
        "riding_name": "Mississauga—Malton",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Labour, Immigration, Training and Skills Development",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/deepak_anand_1.jpg"
    },
    {
        "honorific": "",
        "first_name": "Jill",
        "last_name": "Andrew",
        "gender": "Female",
        "riding_name": "Toronto—St. Paul's",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/jill-andrew.jpg"
    },
    {
        "honorific": "",
        "first_name": "Teresa",
        "last_name": "Armstrong",
        "gender": "Female",
        "riding_name": "London—Fanshawe",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/teresa_armstrong_0.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Ted",
        "last_name": "Arnott",
        "gender": "Male",
        "riding_name": "Wellington—Halton Hills",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/ted_arnott_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Aris",
        "last_name": "Babikian",
        "gender": "Male",
        "riding_name": "Scarborough—Agincourt",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/aris_babikian_.jpg"
    },
    {
        "honorific": "",
        "first_name": "Robert",
        "last_name": "Bailey",
        "gender": "Male",
        "riding_name": "Sarnia—Lambton",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Solicitor General",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/bob_bailey_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Patrice",
        "last_name": "Barnes",
        "gender": "Female",
        "riding_name": "Ajax",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Education",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Patrice_Barnes.png"
    },
    {
        "honorific": "",
        "first_name": "Doly",
        "last_name": "Begum",
        "gender": "Female",
        "riding_name": "Scarborough Southwest",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/doly_begum_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Jessica",
        "last_name": "Bell",
        "gender": "Female",
        "riding_name": "University—Rosedale",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/jessica_bell.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Peter",
        "last_name": "Bethlenfalvy",
        "gender": "Male",
        "riding_name": "Pickering—Uxbridge",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Finance",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/peter_bethlenfalvy.jpg"
    },
    {
        "honorific": "",
        "first_name": "Stephen",
        "last_name": "Blais",
        "gender": "Male",
        "riding_name": "Orléans",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/stephen_blais_180x270.jpg"
    },
    {
        "honorific": "",
        "first_name": "Will",
        "last_name": "Bouma",
        "gender": "Male",
        "riding_name": "Brantford—Brant",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/will_bouma.jpg"
    },
    {
        "honorific": "",
        "first_name": "Guy",
        "last_name": "Bourgouin",
        "gender": "Male",
        "riding_name": "Mushkegowuk—James Bay",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/guy_bourgouin.jpg"
    },
    {
        "honorific": "",
        "first_name": "Stephanie",
        "last_name": "Bowman",
        "gender": "Female",
        "riding_name": "Don Valley West",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Stephanie_Bowman_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Bobbi Ann",
        "last_name": "Brady",
        "gender": "Female",
        "riding_name": "Haldimand—Norfolk",
        "party": "Independent",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Bobbi_Ann_Brady.jpg"
    },
    {
        "honorific": "",
        "first_name": "Ric",
        "last_name": "Bresee",
        "gender": "Male",
        "riding_name": "Hastings—Lennox and Addington",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Natural Resources and Forestry",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Ric_Bresee.png"
    },
    {
        "honorific": "",
        "first_name": "Jeff",
        "last_name": "Burch",
        "gender": "Male",
        "riding_name": "Niagara Centre",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/jeff_burch.jpg"
    },
    {
        "honorific": "",
        "first_name": "Rick",
        "last_name": "Byers",
        "gender": "Male",
        "riding_name": "Bruce—Grey—Owen Sound",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Finance",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Rick_Byers.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "Paul",
        "last_name": "Calandra",
        "gender": "Male",
        "riding_name": "Markham—Stouffville",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Long-Term Care; Minister of Legislative Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/paul_calandra_v2.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Stan",
        "last_name": "Cho",
        "gender": "Male",
        "riding_name": "Willowdale",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Associate Minister of Transportation",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/stan_cho.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Raymond Sung Joon",
        "last_name": "Cho",
        "gender": "Male",
        "riding_name": "Scarborough North",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister for Seniors and Accessibility",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/raymond_cho_0.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Steve",
        "last_name": "Clark",
        "gender": "Male",
        "riding_name": "Leeds—Grenville—Thousand Islands and Rideau Lakes",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Municipal Affairs and Housing",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/steve_clark_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Lorne",
        "last_name": "Coe",
        "gender": "Male",
        "riding_name": "Whitby",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Premier",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/lorne_coe_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Lucille",
        "last_name": "Collard",
        "gender": "Female",
        "riding_name": "Ottawa—Vanier",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Lucille_Collard_0.jpeg"
    },
    {
        "honorific": "",
        "first_name": "Stephen",
        "last_name": "Crawford",
        "gender": "Male",
        "riding_name": "Oakville",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Finance",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/stephen_crawford.jpg"
    },
    {
        "honorific": "",
        "first_name": "Rudy",
        "last_name": "Cuzzetto",
        "gender": "Male",
        "riding_name": "Mississauga—Lakeshore",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the President of the Treasury Board",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/rudy_cuzzetto.jpg"
    },
    {
        "honorific": "",
        "first_name": "Jess",
        "last_name": "Dixon",
        "gender": "Female",
        "riding_name": "Kitchener South—Hespeler",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Energy",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Jess_Dixon.png"
    },
    {
        "honorific": "",
        "first_name": "Andrew",
        "last_name": "Dowie",
        "gender": "Male",
        "riding_name": "Windsor—Tecumseh",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Economic Development, Job Creation and Trade",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Andrew_Dowie.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "Doug",
        "last_name": "Downey",
        "gender": "Male",
        "riding_name": "Barrie—Springwater—Oro-Medonte",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Attorney General",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/doug_downey.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Jill",
        "last_name": "Dunlop",
        "gender": "Female",
        "riding_name": "Simcoe North",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Colleges and Universities",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/jill_dunlop.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Victor",
        "last_name": "Fedeli",
        "gender": "Male",
        "riding_name": "Nipissing",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Chair of Cabinet; Minister of Economic Development, Job Creation and Trade",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/victor_fedeli_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Catherine",
        "last_name": "Fife",
        "gender": "Female",
        "riding_name": "Waterloo",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/catherine_fife_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Rob",
        "last_name": "Flack",
        "gender": "Male",
        "riding_name": "Elgin—Middlesex—London",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Agriculture, Food and Rural Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Rob_Flack.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "Michael",
        "last_name": "Ford",
        "gender": "Male",
        "riding_name": "York South—Weston",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Citizenship and Multiculturalism",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Michael_Ford.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Doug",
        "last_name": "Ford",
        "gender": "Male",
        "riding_name": "Etobicoke North",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Intergovernmental Affairs; Premier",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/doug_ford.jpg"
    },
    {
        "honorific": "",
        "first_name": "John",
        "last_name": "Fraser",
        "gender": "Male",
        "riding_name": "Ottawa South",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/john_fraser.jpg"
    },
    {
        "honorific": "",
        "first_name": "Jennifer",
        "last_name": "French",
        "gender": "Female",
        "riding_name": "Oshawa",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Jennifer_French.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Merrilee",
        "last_name": "Fullerton",
        "gender": "Female",
        "riding_name": "Kanata—Carleton",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Children, Community and Social Services",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/merrilee_fullerton.jpg"
    },
    {
        "honorific": "",
        "first_name": "Dawn",
        "last_name": "Gallagher Murphy",
        "gender": "Female",
        "riding_name": "Newmarket—Aurora",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Health",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Dawn_Gallagher_Murphy.png"
    },
    {
        "honorific": "",
        "first_name": "Wayne",
        "last_name": "Gates",
        "gender": "Male",
        "riding_name": "Niagara Falls",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/wayne_gates_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "France",
        "last_name": "Gélinas",
        "gender": "Female",
        "riding_name": "Nickel Belt",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/france_gelinas_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Goldie",
        "last_name": "Ghamari",
        "gender": "Female",
        "riding_name": "Carleton",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/ghamari_goldie.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Parm",
        "last_name": "Gill",
        "gender": "Male",
        "riding_name": "Milton",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Red Tape Reduction",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/parm_gill_v1.jpg"
    },
    {
        "honorific": "",
        "first_name": "Chris",
        "last_name": "Glover",
        "gender": "Male",
        "riding_name": "Spadina—Fort York",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/chris_glover.jpg"
    },
    {
        "honorific": "",
        "first_name": "Lisa",
        "last_name": "Gretzky",
        "gender": "Female",
        "riding_name": "Windsor West",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/lisa_gretzky_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Hardeep",
        "last_name": "Grewal",
        "gender": "Male",
        "riding_name": "Brampton East",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Transportation",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Hardeep_Grewal.png"
    },
    {
        "honorific": "",
        "first_name": "Ernie",
        "last_name": "Hardeman",
        "gender": "Male",
        "riding_name": "Oxford",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/ernie_hardeman_1.jpg"
    },
    {
        "honorific": "",
        "first_name": "Joel",
        "last_name": "Harden",
        "gender": "Male",
        "riding_name": "Ottawa Centre",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/joel_harden.jpg"
    },
    {
        "honorific": "",
        "first_name": "Mike",
        "last_name": "Harris",
        "gender": "Male",
        "riding_name": "Kitchener—Conestoga",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Legislative Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/mike_harris.jpg"
    },
    {
        "honorific": "",
        "first_name": "Christine",
        "last_name": "Hogarth",
        "gender": "Female",
        "riding_name": "Etobicoke—Lakeshore",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Solicitor General",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/christine_hogarth.jpg"
    },
    {
        "honorific": "",
        "first_name": "Kevin",
        "last_name": "Holland",
        "gender": "Male",
        "riding_name": "Thunder Bay—Atikokan",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Municipal Affairs and Housing",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Kevin_Holland.png"
    },
    {
        "honorific": "",
        "first_name": "Ted",
        "last_name": "Hsu",
        "gender": "Male",
        "riding_name": "Kingston and the Islands",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Ted_Hsu.jpg"
    },
    {
        "honorific": "",
        "first_name": "Mitzie",
        "last_name": "Hunter",
        "gender": "Female",
        "riding_name": "Scarborough—Guildwood",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/mitzie_hunter.jpg"
    },
    {
        "honorific": "",
        "first_name": "Trevor",
        "last_name": "Jones",
        "gender": "Male",
        "riding_name": "Chatham-Kent—Leamington",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Agriculture, Food and Rural Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Trevor_Jones.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "Sylvia",
        "last_name": "Jones",
        "gender": "Female",
        "riding_name": "Dufferin—Caledon",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Deputy Premier; Minister of Health",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Sylvia_Jones.jpeg"
    },
    {
        "honorific": "",
        "first_name": "John",
        "last_name": "Jordan",
        "gender": "Male",
        "riding_name": "Lanark—Frontenac—Kingston",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Long-Term Care",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/John_Jordan.png"
    },
    {
        "honorific": "",
        "first_name": "Logan",
        "last_name": "Kanapathi",
        "gender": "Male",
        "riding_name": "Markham—Thornhill",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Children, Community and Social Services",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Logan_Kanapathi.jpg"
    },
    {
        "honorific": "",
        "first_name": "Bhutila",
        "last_name": "Karpoche",
        "gender": "Female",
        "riding_name": "Parkdale—High Park",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/bhutila_karpoche.jpg"
    },
    {
        "honorific": "",
        "first_name": "Vincent",
        "last_name": "Ke",
        "gender": "Male",
        "riding_name": "Don Valley North",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Public and Business Service Delivery",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/vincent_ke.jpg"
    },
    {
        "honorific": "",
        "first_name": "Terence",
        "last_name": "Kernaghan",
        "gender": "Male",
        "riding_name": "London North Centre",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/terence_kernaghan_0.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Michael",
        "last_name": "Kerzner",
        "gender": "Male",
        "riding_name": "York Centre",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Solicitor General",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Michael_Kerzner.png"
    },
    {
        "honorific": "",
        "first_name": "Andrea",
        "last_name": "Khanjin",
        "gender": "Female",
        "riding_name": "Barrie—Innisfil",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Intergovernmental Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/andrea_khanjin.jpg"
    },
    {
        "honorific": "",
        "first_name": "Natalia",
        "last_name": "Kusendova-Bashta",
        "gender": "Female",
        "riding_name": "Mississauga Centre",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Francophone Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/natalia_kusendova.jpg"
    },
    {
        "honorific": "",
        "first_name": "Anthony",
        "last_name": "Leardi",
        "gender": "Male",
        "riding_name": "Essex",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Mines",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Anthony_Leardi.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "Stephen",
        "last_name": "Lecce",
        "gender": "Male",
        "riding_name": "King—Vaughan",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Education",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/stephen_lecce_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Laura Mae",
        "last_name": "Lindo",
        "gender": "Female",
        "riding_name": "Kitchener Centre",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/lauramae_lindo.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Neil",
        "last_name": "Lumsden",
        "gender": "Male",
        "riding_name": "Hamilton East—Stoney Creek",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Tourism, Culture and Sport",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Neil_Lumsden.png"
    },
    {
        "honorific": "",
        "first_name": "Lisa",
        "last_name": "MacLeod",
        "gender": "Female",
        "riding_name": "Nepean",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/lisa_macleod_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Sol",
        "last_name": "Mamakwa",
        "gender": "Male",
        "riding_name": "Kiiwetinoong",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Sol_Mamakwa_v1.jpg"
    },
    {
        "honorific": "",
        "first_name": "Michael",
        "last_name": "Mantha",
        "gender": "Male",
        "riding_name": "Algoma—Manitoulin",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/michael_mantha_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Robin",
        "last_name": "Martin",
        "gender": "Female",
        "riding_name": "Eglinton—Lawrence",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Health",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/robin_martin_photo.jpg"
    },
    {
        "honorific": "",
        "first_name": "Todd",
        "last_name": "McCarthy",
        "gender": "Male",
        "riding_name": "Durham",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the President of the Treasury Board",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Todd_McCarthy.png"
    },
    {
        "honorific": "",
        "first_name": "Graham",
        "last_name": "McGregor",
        "gender": "Male",
        "riding_name": "Brampton North",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Citizenship and Multiculturalism",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Graham_McGregor.png"
    },
    {
        "honorific": "",
        "first_name": "Mary-Margaret",
        "last_name": "McMahon",
        "gender": "Female",
        "riding_name": "Beaches—East York",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Mary_Margaret_McMahon.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Monte",
        "last_name": "McNaughton",
        "gender": "Male",
        "riding_name": "Lambton—Kent—Middlesex",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Labour, Immigration, Training and Skills Development",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/monte_mcnaughton_v2.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Caroline",
        "last_name": "Mulroney",
        "gender": "Female",
        "riding_name": "York—Simcoe",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Francophone Affairs; Minister of Transportation",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/caroline_mulroney.jpg"
    },
    {
        "honorific": "",
        "first_name": "Sam",
        "last_name": "Oosterhoff",
        "gender": "Male",
        "riding_name": "Niagara West",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Red Tape Reduction",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Sam_Oosterhoff.png"
    },
    {
        "honorific": "",
        "first_name": "Billy",
        "last_name": "Pang",
        "gender": "Male",
        "riding_name": "Markham—Unionville",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Citizenship and Multiculturalism",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/billy_pang_0.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Michael",
        "last_name": "Parsa",
        "gender": "Male",
        "riding_name": "Aurora—Oak Ridges—Richmond Hill",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Associate Minister of Housing",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/michael_parsa.jpg"
    },
    {
        "honorific": "",
        "first_name": "Chandra",
        "last_name": "Pasma",
        "gender": "Female",
        "riding_name": "Ottawa West—Nepean",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Chandra_Pasma.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "David",
        "last_name": "Piccini",
        "gender": "Male",
        "riding_name": "Northumberland—Peterborough South",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of the Environment, Conservation and Parks",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/david_piccini_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Natalie",
        "last_name": "Pierre",
        "gender": "Female",
        "riding_name": "Burlington",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Colleges and Universities",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Natalie_Pierre.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "George",
        "last_name": "Pirie",
        "gender": "Male",
        "riding_name": "Timmins",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Mines",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/George_Pirie.png"
    },
    {
        "honorific": "",
        "first_name": "Nolan",
        "last_name": "Quinn",
        "gender": "Male",
        "riding_name": "Stormont—Dundas—South Glengarry",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Children, Community and Social Services",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Nolan_Quinn.png"
    },
    {
        "honorific": "",
        "first_name": "Matthew",
        "last_name": "Rae",
        "gender": "Male",
        "riding_name": "Perth—Wellington",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Education",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Matt_Rae.png"
    },
    {
        "honorific": "",
        "first_name": "Tom",
        "last_name": "Rakocevic",
        "gender": "Male",
        "riding_name": "Humber River—Black Creek",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/tom_rakocevic.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Kaleed",
        "last_name": "Rasheed",
        "gender": "Male",
        "riding_name": "Mississauga East—Cooksville",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Public and Business Service Delivery",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/kaleed_rasheed_.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Greg",
        "last_name": "Rickford",
        "gender": "Male",
        "riding_name": "Kenora—Rainy River",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Northern Development; Minister of Indigenous Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Greg%20Rickford.jpeg"
    },
    {
        "honorific": "",
        "first_name": "Brian",
        "last_name": "Riddell",
        "gender": "Male",
        "riding_name": "Cambridge",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Public and Business Service Delivery",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Brian_Riddell.png"
    },
    {
        "honorific": "",
        "first_name": "Ross",
        "last_name": "Romano",
        "gender": "Male",
        "riding_name": "Sault Ste. Marie",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/ross_romano_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Sheref",
        "last_name": "Sabawy",
        "gender": "Male",
        "riding_name": "Mississauga—Erin Mills",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Tourism, Culture and Sport",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/sheref_sabawy.jpg"
    },
    {
        "honorific": "",
        "first_name": "Amarjot",
        "last_name": "Sandhu",
        "gender": "Male",
        "riding_name": "Brampton West",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Infrastructure",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/amarjot_sandhu_0.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Prabmeet Singh",
        "last_name": "Sarkaria",
        "gender": "Male",
        "riding_name": "Brampton South",
        "party": "Progressive Conservative Party of Ontario",
        "role": "President of the Treasury Board",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/prabmeet_sarkaria.jpg"
    },
    {
        "honorific": "",
        "first_name": "Stéphane",
        "last_name": "Sarrazin",
        "gender": "Male",
        "riding_name": "Glengarry—Prescott—Russell",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Energy",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Stephane_Sarrazin.png"
    },
    {
        "honorific": "",
        "first_name": "Peggy",
        "last_name": "Sattler",
        "gender": "Female",
        "riding_name": "London West",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/peggy_sattler.jpg"
    },
    {
        "honorific": "",
        "first_name": "Brian",
        "last_name": "Saunderson",
        "gender": "Male",
        "riding_name": "Simcoe—Grey",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Attorney General",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Brian_Saunderson.jpeg"
    },
    {
        "honorific": "",
        "first_name": "Mike",
        "last_name": "Schreiner",
        "gender": "Male",
        "riding_name": "Guelph",
        "party": "Green Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/mike_schreiner_v1.jpg"
    },
    {
        "honorific": "",
        "first_name": "Laurie",
        "last_name": "Scott",
        "gender": "Female",
        "riding_name": "Haliburton—Kawartha Lakes—Brock",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/laurie_scott_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Adil",
        "last_name": "Shamji",
        "gender": "Male",
        "riding_name": "Don Valley East",
        "party": "Ontario Liberal Party",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Adil_Shamji.jpg"
    },
    {
        "honorific": "",
        "first_name": "Sandy",
        "last_name": "Shaw",
        "gender": "Female",
        "riding_name": "Hamilton West—Ancaster—Dundas",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/sandy_shaw.jpg"
    },
    {
        "honorific": "",
        "first_name": "Donna",
        "last_name": "Skelly",
        "gender": "Female",
        "riding_name": "Flamborough—Glanbrook",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/donna_skelly.jpg"
    },
    {
        "honorific": "",
        "first_name": "David",
        "last_name": "Smith",
        "gender": "Male",
        "riding_name": "Scarborough Centre",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Labour, Immigration, Training and Skills Development",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/David_Smith.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "Graydon",
        "last_name": "Smith",
        "gender": "Male",
        "riding_name": "Parry Sound—Muskoka",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Natural Resources and Forestry",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Graydon_Smith.png"
    },
    {
        "honorific": "",
        "first_name": "Laura",
        "last_name": "Smith",
        "gender": "Female",
        "riding_name": "Thornhill",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Tourism, Culture and Sport",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Laura_Smith.png"
    },
    {
        "honorific": "Hon.",
        "first_name": "Todd",
        "last_name": "Smith",
        "gender": "Male",
        "riding_name": "Bay of Quinte",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Energy",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/todd_smith_1.jpg"
    },
    {
        "honorific": "",
        "first_name": "Dave",
        "last_name": "Smith",
        "gender": "Male",
        "riding_name": "Peterborough—Kawartha",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Indigenous Affairs; Parliamentary Assistant to the Minister of Northern Development",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/dave_smith.jpg"
    },
    {
        "honorific": "",
        "first_name": "Jennifer (Jennie)",
        "last_name": "Stevens",
        "gender": "Female",
        "riding_name": "St. Catharines",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/jennie_stevens.jpg"
    },
    {
        "honorific": "",
        "first_name": "Marit",
        "last_name": "Stiles",
        "gender": "Female",
        "riding_name": "Davenport",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/marit_stiles.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Kinga",
        "last_name": "Surma",
        "gender": "Female",
        "riding_name": "Etobicoke Centre",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Infrastructure",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/kinga_surma.jpg"
    },
    {
        "honorific": "",
        "first_name": "Peter",
        "last_name": "Tabuns",
        "gender": "Male",
        "riding_name": "Toronto—Danforth",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Peter_Tabuns_v2.jpg"
    },
    {
        "honorific": "",
        "first_name": "Nina",
        "last_name": "Tangri",
        "gender": "Female",
        "riding_name": "Mississauga—Streetsville",
        "party": "Progressive Conservative Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/nina_tangri.jpg"
    },
    {
        "honorific": "",
        "first_name": "Monique",
        "last_name": "Taylor",
        "gender": "Female",
        "riding_name": "Hamilton Mountain",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Monique_Taylor.jpg"
    },
    {
        "honorific": "",
        "first_name": "Vijay",
        "last_name": "Thanigasalam",
        "gender": "Male",
        "riding_name": "Scarborough—Rouge Park",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Infrastructure",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/vijay_thanigasalam_v1.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Lisa",
        "last_name": "Thompson",
        "gender": "Female",
        "riding_name": "Huron—Bruce",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Minister of Agriculture, Food and Rural Affairs",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/lisa_thompson.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Michael A.",
        "last_name": "Tibollo",
        "gender": "Male",
        "riding_name": "Vaughan—Woodbridge",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Associate Minister of Mental Health and Addictions",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/michael_tibollo.jpg"
    },
    {
        "honorific": "",
        "first_name": "Effie J.",
        "last_name": "Triantafilopoulos",
        "gender": "Female",
        "riding_name": "Oakville North—Burlington",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of Economic Development, Job Creation and Trade",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/effie_triantafilopoulos.jpg"
    },
    {
        "honorific": "",
        "first_name": "John",
        "last_name": "Vanthof",
        "gender": "Male",
        "riding_name": "Timiskaming—Cochrane",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/john_vanthof.jpg"
    },
    {
        "honorific": "",
        "first_name": "Lise",
        "last_name": "Vaugeois",
        "gender": "Female",
        "riding_name": "Thunder Bay—Superior North",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Lise_Vaugeois.png"
    },
    {
        "honorific": "",
        "first_name": "Daisy",
        "last_name": "Wai",
        "gender": "Female",
        "riding_name": "Richmond Hill",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister for Seniors and Accessibility",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/daisy_wai_0.jpg"
    },
    {
        "honorific": "",
        "first_name": "Jamie",
        "last_name": "West",
        "gender": "Male",
        "riding_name": "Sudbury",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/jamie_west.jpg"
    },
    {
        "honorific": "Hon.",
        "first_name": "Charmaine",
        "last_name": "Williams",
        "gender": "Female",
        "riding_name": "Brampton Centre",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Associate Minister of Women's Social and Economic Opportunity",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Charmaine_Williams.png"
    },
    {
        "honorific": "",
        "first_name": "Kristyn",
        "last_name": "Wong-Tam",
        "gender": "Female",
        "riding_name": "Toronto Centre",
        "party": "New Democratic Party of Ontario",
        "role": "",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/Kristyn_Wong_Tam.png"
    },
    {
        "honorific": "",
        "first_name": "John",
        "last_name": "Yakabuski",
        "gender": "Male",
        "riding_name": "Renfrew—Nipissing—Pembroke",
        "party": "Progressive Conservative Party of Ontario",
        "role": "Parliamentary Assistant to the Minister of the Environment, Conservation and Parks",
        "image": "https://www.ola.org/sites/default/files/member/profile-photo/john_yakabuski_0.jpg"
    }
]
