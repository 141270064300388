import React from 'react';
import './App.css';
import { MPPS } from '../data/mpps';
import { MppCard } from './MppCard';
import { GuessName } from './GuessName';
import { GuessParty } from './GuessParty';
import { GuessRiding } from './GuessRiding';


type AppState = {
    mpp: number,
    validate: boolean,
    selections: boolean[]
}

class App extends React.Component<{}, AppState> {

    getRandomMpp = (): number => {
        return Math.floor(Math.random() * MPPS.length);
    }

    state = {
        mpp: 0,
        validate: false,
        selections: [false, false, false]
    }

    componentDidMount() {
        this.next();
    }

    next = () => {
        this.setState({
            mpp: this.getRandomMpp(),
            validate: false,
            selections: [false, false, false]
        })
    }

    check = () => {
        this.setState({ validate: true })
    }

    onPartyChange = (party: string) => {
        this.state.selections[0] = true;
        this.setState({ selections: this.state.selections })
    }

    onRidingChange = (riding: string) => {
        this.state.selections[1] = true;
        this.setState({ selections: this.state.selections })
    }

    onNameChange = (name: string) => {
        this.state.selections[2] = true;
        this.setState({ selections: this.state.selections })
    }

    allSelected = () => {
        return this.state.selections.indexOf(false) === -1;
    }

    render() {
        return (
            <div className="App" >
                <h1>Know Your Ontario MPP</h1>
                <h2>43rd Parliament (August 8, 2022)</h2>
                <MppCard key={this.state.mpp} mpp={this.state.mpp} hide={!this.state.validate}></MppCard>
                <div className="ui stackable centered grid" key={'G' + this.state.mpp}>
                    <GuessName mpp={this.state.mpp} validate={this.state.validate} onChange={this.onNameChange} />
                    <GuessRiding mpp={this.state.mpp} validate={this.state.validate} onChange={this.onRidingChange} />
                    <GuessParty mpp={this.state.mpp} validate={this.state.validate} onChange={this.onPartyChange} />
                </div>
                <button className="ui button" onClick={this.check} disabled={!this.allSelected()}>
                    Check
                </button>
                <button className="ui right labeled icon button" onClick={this.next} disabled={!this.state.validate}>
                    <i className="right arrow icon"></i>
                    Next
                </button>
            </div >
        );
    }
}

export default App;
