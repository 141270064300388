import React from 'react';
import './GuessName.css';
import { MPPS, Mpp } from '../data/mpps';

import sampleSize from 'lodash/sampleSize';
import shuffle from 'lodash/shuffle';

type GuessNameProps = {
    onChange: (riding: string) => void;
    mpp: number;
    validate: boolean;
}

export class GuessName extends React.Component<GuessNameProps> {

    nameSelections: string[];

    state = {
        selection: ''
    }


    constructor(props: GuessNameProps) {
        super(props);

        const otherMpps = [...MPPS]
        otherMpps.splice(this.props.mpp, 1)

        const cur: Mpp = MPPS[this.props.mpp];

        this.nameSelections = shuffle([...sampleSize(otherMpps.filter(x => x.gender === cur.gender)
            .map(x => getName(x)), 3), getName(cur)]);
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event.target.value)
        this.setState({ selection: event.target.value })
    }

    validate = (input: string): any => {
        if (this.props.validate) {
            if (input === this.state.selection) {
                if (this.state.selection === getName(MPPS[this.props.mpp])) {
                    return <i className="check green circle icon"></i>
                } else {
                    return <i className="close red icon"></i>
                }
            } else if (input === getName(MPPS[this.props.mpp])) {
                return <i className="check green circle icon"></i>
            }
        }
    }

    render() {

        return (
            <div className="four wide column name" onChange={this.onChange} >
                <p>Select name:</p>
                {this.nameSelections.map((name) =>
                (<div key={name}>
                    <label>
                        <input type="radio" value={name} name="name" disabled={this.props.validate} />
                        {name}
                    </label>
                    {this.validate(name)}
                    <br />
                </div>))}

            </div>
        );
    }
}

function getName(mpp: Mpp) {
    return mpp.first_name + " " + mpp.last_name;
}