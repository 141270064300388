import React from 'react';
import { Mpp } from '../data/mpps';
import { MPPS } from '../data/mpps';
import './MppCard.css';

type MppProps = {
    mpp: number;
    hide: boolean;
}

export class MppCard extends React.Component<MppProps> {

    mpp: Mpp;
    hide: boolean;

    constructor(props: MppProps) {

        super(props);
        this.mpp = MPPS[props.mpp];
        this.hide = props.hide;
    }

    render() {
        return (
            <div className="ui stackable two column grid middle aligned">
                <div className="column">
                    <img src={this.mpp.image} />
                </div>
                <div className={this.props.hide ? 'hidden' : undefined + " column"}>
                    <div>{this.mpp.honorific} {this.mpp.first_name} {this.mpp.last_name}</div>
                    <div>{this.mpp.riding_name}</div>
                    <div>{this.mpp.party}</div>
                </div>
            </div >
        );
    }
}

