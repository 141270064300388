import React from 'react';
import './GuessRiding.css';
import { MPPS } from '../data/mpps';

import sampleSize from 'lodash/sampleSize';
import shuffle from 'lodash/shuffle';

type GuessRidingProps = {
    onChange: (riding: string) => void;
    mpp: number;
    validate: boolean;
}

export class GuessRiding extends React.Component<GuessRidingProps> {

    ridingSelections: string[];

    state = {
        selection: ''
    }

    constructor(props: GuessRidingProps) {
        super(props);

        const otherMpps = [...MPPS]
        otherMpps.splice(this.props.mpp, 1)

        this.ridingSelections = shuffle([...sampleSize(otherMpps.map(x => x.riding_name), 3), MPPS[this.props.mpp].riding_name]);
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event.target.value)
        this.setState({ selection: event.target.value })
    }

    validate = (input: string): any => {
        if (this.props.validate) {
            if (input === this.state.selection) {
                if (this.state.selection === MPPS[this.props.mpp].riding_name) {
                    return <i className="check green circle icon"></i>
                } else {
                    return <i className="close red icon"></i>
                }
            } else if (input === MPPS[this.props.mpp].riding_name) {
                return <i className="check green circle icon"></i>
            }
        }
    }

    render() {

        return (
            <div className="four wide column riding" onChange={this.onChange} >
                <p>Select riding:</p>
                {this.ridingSelections.map((riding) =>
                (<div key={riding}>
                    <label>
                        <input type="radio" value={riding} name="riding" disabled={this.props.validate} />
                        {riding}
                    </label>
                    {this.validate(riding)}
                    <br />
                </div>))}

            </div>
        );
    }
}

