import React from 'react';
import './GuessParty.css';
import { Mpp, MPPS } from '../data/mpps';

type GuessPartyProps = {
    onChange: (party: string) => void;
    mpp: number;
    validate: boolean;
}

const PARTIES = [
    { display: "PC", name: "Progressive Conservative Party of Ontario" },
    { display: "NDP", name: "New Democratic Party of Ontario" },
    { display: "Liberal", name: "Ontario Liberal Party" },
    { display: "Green", name: "Green Party of Ontario" },
    { display: "Independent", name: "Independent" }
];

export class GuessParty extends React.Component<GuessPartyProps> {

    state = {
        selection: ''
    }

    onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event.target.value)
        this.setState({ selection: event.target.value })
    }

    validate = (input: string): any => {
        if (this.props.validate) {
            if (input === this.state.selection) {
                if (this.state.selection === MPPS[this.props.mpp].party) {
                    return <i className="check green circle icon"></i>
                } else {
                    return <i className="close red icon"></i>
                }
            } else if (input === MPPS[this.props.mpp].party) {
                return <i className="check green circle icon"></i>
            }
        }
    }

    render() {
        return (
            <div className="four wide column party" onChange={this.onChange} >
                <p>Select party:</p>
                {PARTIES.map((party) =>
                    <div key={party.display}>
                        <label>
                            <input type="radio" value={party.name} name="party" disabled={this.props.validate} />
                            {party.display}
                        </label>
                        {this.validate(party.name)}
                        < br />
                    </div>
                )}

            </div>
        );
    }
}

